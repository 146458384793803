<template>
  <div class="flex flex-row h-full relative">
    <div :class="`${isDrawerOpen ? 'w-1/2' : 'w-full'} h-full relative`">
      <!-- <h1 class="absolute left-0 bottom-0">Search</h1>   -->
      <div class="absolute left-0 top-0 mt-4 ml-4 w-1/2 z-50">
        <!-- <el-input
          id="addressField"
          ref="address" prefix-icon="el-icon-search" v-model="form.search" placeholder="Cari Alamat..." /> -->
        <el-autocomplete
          v-model="form.search"
          class="w-full"
          prefix-icon="el-icon-search"
          :fetch-suggestions="querySearchPlaces"
          placeholder="Cari Alamat"
          value-key="name"
          @select="handleSelectPlace"
        />
      </div>
      <div
        id="map"
        ref="map"
        v-loading="loading.map"
        class="w-full h-full"
      >
        <el-popover
          v-model="popover.show"
          placement="bottom"
          title="Title"
          width="200"
          class="popover"
          :style="{ marginLeft: popover.x + 'px', marginTop: popover.y + 'px', }"
          trigger="manual"
        >
          <!-- <el-button slot="reference" @click="popover = !popover">Manual to activate</el-button> -->
        </el-popover>
        <!-- <el-drawer
          title="I am the title"
          :visible.sync="drawer.open"
          :direction="drawer.direction"
        >
          <span>Hi, there!</span>
        </el-drawer> -->
      </div>
    </div> 
    <div
      v-show="isDrawerOpen"
      id="drawerDetail"
      :key="key.drawer"
      class="w-1/2 bg-gray-800"
    >
      <template v-if="mode === 'detail'">
        <div
          class="bg-gray-500 h-8 flex items-center justify-end px-2 text-white text-xs"
        >
          <span class="mr-auto">Camera Name</span>
          <el-tooltip
            v-if="user.data.role_id.stream_mode !== 'active'"
            class="item"
            effect="dark"
            content="Tampilan Penuh"
            placement="left"
          >
            <i
              class="cursor-pointer ri-fullscreen-line ri-lg"
              @click="toggleFullscreen()"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Kirim Notifikasi"
            placement="top"
          >
            <i
              class="cursor-pointer ri-share-forward-fill ri-lg ml-3"
              @click="notify()"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Sembunyikan"
            placement="top"
          >
            <i
              class="cursor-pointer ri-close-line ri-2x"
              @click="closeDrawer"
            />
          </el-tooltip>
        </div>
        <div
          class="bg-gray-400"
          style="height: auto"
        >
          <template v-if="user.data.role_id.stream_mode === 'passive'">
            <fullscreen
              ref="fullscreen"
              @change="fullscreenChange"
            >
              <AutoReloadImage :src="`http://proxy.cctv.malangkota.go.id/image?host=${drawer.data.host}`" />
            </fullscreen>
          </template>
          <iframe
            v-if="user.data.role_id.stream_mode === 'active'"
            width="100%"
            style="height: 50vh"
            :src="`//stream.cctv.malangkota.go.id:/WebRTCApp/play.html?name=${drawer.data.stream_id}`"
            frameborder="0"
            allowfullscreen
          />
        </div>
        <div class="p-4 text-sm">
          <ul>
            <li class="flex flex-row mb-2">
              <div class="w-1/3 text-blue-600">
                Nama
              </div>
              <div class="w-2/3 text-white">
                {{ drawer.data.name }}
              </div>
            </li>
            <li class="flex flex-row mb-2">
              <div class="w-1/3 text-blue-600">
                Alamat
              </div>
              <div class="w-2/3 text-white">
                {{ drawer.data.address }}
              </div>
            </li>
            <li class="flex flex-row mb-2">
              <div class="w-1/3 text-blue-600">
                Koordinat
              </div>
              <div class="w-2/3 text-white">
                {{ drawer.data.latitude }}, {{ drawer.data.longitude }}
              </div>
            </li>
            <!-- <li class="flex flex-row mb-2">
              <div class="w-1/3 text-blue-600">URL</div>
              <div class="w-2/3 text-white break-words"></div>
            </li> -->
            <li class="flex flex-row mb-2">
              <div class="w-1/3 text-blue-600">
                Tipe Jalan
              </div>
              <div class="w-2/3 text-white">
                <span class="text-white">
                  {{ drawer.data.isIntersection ? 'Persimpangan' : 'Ruas Jalan' }}
                </span>
              </div>
            </li>
            <li class="flex flex-row mb-2">
              <div class="w-1/3 text-blue-600">
                Tampil Publik
              </div>
              <div class="w-2/3 text-white">
                <span
                  v-if="drawer.data.isPublic"
                  class="text-green-600"
                >Ya</span>
                <span
                  v-if="!drawer.data.isPublic"
                  class="text-red-600"
                >Tidak</span>
              </div>
            </li>
            <li class="flex flex-row mb-2">
              <div class="w-1/3 text-blue-600">
                Status
              </div>
              <div class="w-2/3 text-white">
                <span
                  v-if="drawer.data.status"
                  class="text-green-600"
                >Aktif</span>
                <span
                  v-if="!drawer.data.status"
                  class="text-red-600"
                >Tidak Aktif</span>
              </div>
            </li>
          </ul>
          <!-- <el-button icon="el-icon-edit" type="primary" round>Edit</el-button> -->
        </div>
      </template>
      <template v-if="mode === 'new'">
        <div class="p-4">
          <h1 class="text-white">
            Tambahkan Lokasi CCTV
          </h1>
          <el-divider />
          <el-form
            id="mapForm"
            ref="form"
            :model="form"
            label-position="top"
          >
            <el-form-group
              name="Kamera CCTV"
              label="Pilih Kamera"
              :validator="$v.form.camera_id"
            >
              <el-select
                v-model="form.camera_id"
                class="w-full"
                filterable
                placeholder="Pilih Kamera CCTV"
                @blur="$v.form.camera_id.$touch()"
              >
                <el-option
                  v-for="item in availableCameras"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right">
                    <el-tag
                      class="mx-2"
                      :type="parse.status[item.status].type"
                    >
                      {{ parse.status[item.status].label }}
                    </el-tag>
                    <el-tag
                      class="mx-2"
                      :type="parse.public[item.isPublic].type"
                    >
                      {{ parse.public[item.isPublic].label }}
                    </el-tag>
                  </span>
                </el-option>
              </el-select>
            </el-form-group>

            <el-form-item label="Alamat">
              <el-input
                v-model="form.address"
                disabled
              />
            </el-form-item>
            <el-form-item label="Latitude">
              <el-input
                v-model="form.latitude"
                disabled
              />
            </el-form-item>
            <el-form-item label="Longitude">
              <el-input
                v-model="form.longitude"
                disabled
              />
            </el-form-item>
            <el-form-group
              class="w-full"
              label="Tipe Jalan"
            >
              <el-switch
                v-model="form.isIntersection"
                active-text="Persimpangan"
                inactive-text="Ruas Jalan"
                active-color="blue"
                inactive-color="green"
              />
            </el-form-group>
            <el-form-item>
              <el-button
                type="primary"
                :disabled="$v.$invalid"
                @click="doUpdate"
              >
                Create
              </el-button>
              <el-button @click="closeDrawer">
                Cancel
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </div>
    <NotificationFormDialog
      ref="notification"
    /> 
  </div>
</template>

<script>
import gmapsInit from '@/utils/gmaps.js';
import { required } from "vuelidate/lib/validators";
import AutoReloadImage from '../../../components/AutoReloadImage.component';
import NotificationFormDialog from '../Notifications/NotificationForm.dialog';
import Fullscreen from 'vue-fullscreen/src/component.vue';
// import { getIdFromURL } from 'vue-youtube-embed'
// import MarkerClusterer from 'node-js-marker-clusterer';
export default {
  components: {
    NotificationFormDialog,
    AutoReloadImage,
    Fullscreen
  },
  data() {
    return {
      fullscreen: false,
      user: null,
      interval: null,
      latestImageSrc: null,
      loading: {
        map: false,
        drawer: false
      },
      form: {
        camera_id: null
      },
      key: {
        drawer: 0,
        image: 0,
      },
      parse: {
        status: {
          '0': {
            label: 'Inactive',
            type: 'info'
          },
          '1': {
            label: 'Active',
            type: 'success'
          }
        },
        public: {
          '0': {
            label: 'Private',
            type: 'primary'
          },
          '1': {
            label: 'Public',
            type: 'success'
          }
        }
      },
      google: null,
      map: null,
      geocoder: null,
      placesService: null,
      autocomplete: null,
      bounds: null,
      currentBounds: null,
      infowindow: null,
      markers: [],
      markerCluster: null,
      markerNewLocation: null,
      isDrawerOpen: false,
      showTraffic: false,
      svg: {
        cameraMarker: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" fill="rgba(231,76,60,1)"/></svg>',
        newMarker: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM11 10H8v2h3v3h2v-3h3v-2h-3V7h-2v3z" fill="rgba(50,152,219,1)"/></svg>',
      },
      cameras: [],
      popover: {
        show: false,
        x: 0,
        y: 0,
      },
      drawer: {
        open: false,
        direction: 'rtl',
        data: {}
      },
      mode: 'idle',
      availableCameras: [],
      styles: [
        {
          featureType: "poi",
          stylers: [
            { visibility: "off" }
          ]
        },
        {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
        {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{color: '#d59563'}]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{color: '#d59563'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{color: '#263c3f'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{color: '#6b9a76'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{color: '#38414e'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{color: '#212a37'}]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{color: '#9ca5b3'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: '#746855'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{color: '#1f2835'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{color: '#f3d19c'}]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{color: '#2f3948'}]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{color: '#d59563'}]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: '#17263c'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{color: '#515c6d'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{color: '#17263c'}]
        }
      ]
    }
  },
  async created() {
    this.user = this.$store.getters.getCurrentAuthUser;
    this.stopIntervalImage();
  },
  async mounted() {
    
    try {
      this.loading.map = true;
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      
      const cameras = await this.getAllMappedCameras();
      this.cameras = cameras.records.map(camera => {
        return {
          id: camera.id,
          label: camera.name,
          latitude: camera.latitude,
          longitude: camera.longitude
        }
      });
      
      await this.initMap();
      await this.initMarkers();
      // loading.close();
      this.loading.map = false;
      // console.log(this.$route.params.query);
    } catch (error) {
      // console.error(error);
    }
  },
  validations: {
    form: {
      camera_id: { required }
    }
  },
  methods: {
    async initMap() {
      this.google = await gmapsInit();
      const options = {
        styles: this.styles,
        disableDefaultUI: true
      }
      this.map = new this.google.maps.Map(this.$refs.map, options);
      this.geocoder = new this.google.maps.Geocoder();
      this.placesService = new this.google.maps.places.PlacesService(this.map);


        // center: new this.google.maps.LatLng(this.latitude, this.longitude),

      var trafficLayer = new this.google.maps.TrafficLayer();
      trafficLayer.setMap(this.map);
    },
    // initAutocomplete() {
    //   const el = document.getElementById('addressField');
    //   console.log(this.currentBounds);
    //   const options = {
    //     bounds: this.currentBounds,
    //     types: ['address'],
    //     componentRestrictions: {country: 'id'}
    //   }
    //   this.autocomplete = new this.google.maps.places.Autocomplete(el, options);
    //   this.autocomplete.setFields(['address_component']);
    // },
    async initMarkers() {
      this.markers = [];
      // this.markerCluster = null;
      this.infowindow = new this.google.maps.InfoWindow();
      this.bounds = new this.google.maps.LatLngBounds();

      for(let camera of this.cameras) {
        // console.log(camera);
        const marker = new this.google.maps.Marker({ 
          position: new this.google.maps.LatLng(camera.latitude, camera.longitude),
          map: this.map,
          data: camera,
          icon: {
            // url: 'camera-marker.png',
            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.svg.cameraMarker),
            scaledSize: new this.google.maps.Size(40, 40)
          }
        });
        this.bounds.extend(marker.position);
        this.markers.push(marker);
        this.initMarkerListener(marker, camera);
      }
      // await this.initMarkerCluster();
      // this.markerCluster = new MarkerClusterer(this.map, this.markers,
      //   {
      //     imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      //   }
      // );
      this.initMapListener();
      this.map.fitBounds(this.bounds);
    },
    initMarkerListener(marker, camera) {
      const infowindow = new this.google.maps.InfoWindow();
      marker.addListener('mouseover', () => {
        infowindow.setContent(`<span class="text-gray-900">${camera.label}</span>`);
        infowindow.open(this.map, marker);
      });
      marker.addListener('mouseout', () => {
        infowindow.close();
      });
      marker.addListener('click', async (event) => {
        const loading = this.$loading({
          lock: true,
          text: 'Mohon tunggu, sedang mengambil data CCTV',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.drawer.data = await this.getCamera(marker.data.id);
        this.$router.push(`/dashboard/map?cid=${this.drawer.data.id}`);
        // this.drawer.data.videoId = this.drawer.data.url ? getIdFromURL(this.drawer.data.url) : null;
        this.map.setCenter(event.latLng);
        this.map.setZoom(20);
        this.doOpenDrawer('detail');
        // this.startIntervalImage();
        loading.close();
      })
    },
    startIntervalImage() {
      this.fetchLatestImage();
      this.interval = setInterval(() => {
        this.key.image++;
        // console.log(this.key.image);
      }, 1000);
    },
    stopIntervalImage() {
      if(this.interval) {
        clearInterval(this.interval);
      }
    },
    fetchLatestImage() {
      this.latestImageSrc = `https://picsum.photos/400/300`;
    },
    initMapListener() {
      
      
      this.map.addListener('click', () => {
        this.popover.show = false;
        this.removeMarker(this.markerNewLocation);
        if(this.mode === 'new') {
          this.closeDrawer();
        }
        // infowindow.close();
      });

      if(this.user.data.role_id.name === 'Admin') {
        this.map.addListener('rightclick', async (event) => {
          this.key.drawer++;
          this.form.camera_id = null;
          if(this.markerNewLocation) {
            this.removeMarker(this.markerNewLocation);
          }
          this.popover.x = event.pixel.x
          this.popover.y = event.pixel.y
          this.renderMarkerNewLocation(event.latLng)
  
          this.geocoder.geocode({ 'latLng': event.latLng }, async (result) => {
            this.form.address = result[0].formatted_address;
            this.form.latitude = event.latLng.lat();
            this.form.longitude = event.latLng.lng();
            const cameras = await this.getAvailableCameras();
            this.availableCameras = cameras.records.map(camera => {
              return {
                label: camera.name,
                value: camera.id,
                isPublic: camera.isPublic,
                status: camera.status
              }
            })
            this.doOpenDrawer('new');
          })
        })
      }
    },
    getCamera(camera_id) {
      return new Promise((resolve) => {
        this.$request.get(`records/cameras/${camera_id}`).then(response => {
          resolve(response.data);
        })
      })
    },
    getAllMappedCameras() {
      return new Promise((resolve) => {
        const searchParams = new URLSearchParams();
        searchParams.append('filter', 'address,nis');
        searchParams.append('filter', 'latitude,nis');
        searchParams.append('filter', 'longitude,nis');
        this.$request.get('records/cameras', { params: searchParams }).then(response => {
          resolve(response.data);
        })
      })
    },
    getAvailableCameras() {
      return new Promise((resolve) => {
        const searchParams = new URLSearchParams();
        searchParams.append('filter', 'address,is');
        searchParams.append('filter', 'latitude,is');
        searchParams.append('filter', 'longitude,is');
        this.$request.get('records/cameras', { params: searchParams }).then(response => {
          resolve(response.data);
        })
      })
    },
    toggleFullscreen() {
      // console.log(this.$refs);
      this.$refs.fullscreen.toggle();
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    closeDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
      this.map.fitBounds(this.bounds);
      this.mode = 'idle';
      this.$router.push('/dashboard/map');
    },
    doOpenDrawer(mode) {
      this.mode = mode;
      this.isDrawerOpen = true;
    },
    update() {
      return new Promise((resolve, reject) => {
        const data = {
          address: this.form.address ? this.form.address : null,
          latitude: this.form.latitude ? this.form.latitude : null,
          longitude: this.form.longitude ? this.form.longitude : null,
          updated_at: null,
          isIntersection: this.form.isIntersection ? 1 : 0
        }
        this.$request.put(`records/cameras/${this.form.camera_id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    doUpdate() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        await this.update();
        this.closeDrawer();
        this.$message({
          type: 'success',
          message: 'Operation Success'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    },
    renderMarkerNewLocation(position) {
      this.markerNewLocation = new this.google.maps.Marker({ 
        position: position,
        map: this.map,
        icon: {
          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.svg.newMarker),
          scaledSize: new this.google.maps.Size(40, 40)
        }
      });
      this.map.setZoom(20);
      const infowindow = new this.google.maps.InfoWindow({
        content: '<span class="text-gray-900">Tambah data lokasi CCTV Disini?</span>'
      });
      infowindow.open(this.map, this.markerNewLocation);
      this.map.setCenter(position);
    },
    async removeMarker(markerInstance) {
      await markerInstance.setMap(null);
    },
    notify() {
      this.$refs.notification.openDialog();
      this.$refs.notification.setCameraData(this.drawer.data);
    },
    querySearchPlaces(query, callback) {
      const request = {
        location: new this.google.maps.LatLng(-7.9786439,112.5967636),
        radius: '10000',
        query,
        types: ['address'],
        fields: ['place_id', 'name', 'geometry']
      }
      this.placesService.textSearch(request, (results) => {
        callback(results);
        // const resultList = results.map(result => {
        //   return {
        //     label: result.name,
        //     sublabel: result.formatted_address,
        //     value: result.name,
        //     icon: 'room',
        //     latlng: result.geometry.location
        //   }
        // });
        // done(resultList);
      });
    },
    handleSelectPlace(val) {
      // console.log(val);
      this.locateMap(val.geometry.location)
    },
    locateMap(latLng) {
      this.map.setCenter(latLng);
      this.map.setZoom(18);
    }
  }
}
</script>

<style>

#drawerDetail {
  border-left: 1px solid;
  @apply border-gray-700 
}

#mapForm .el-form-item__label {
  color: white;
}

.gm-ui-hover-effect {opacity: 0 !important;}

</style>